<div class="container">
    <div class="header" *ngrxLet="activeLanguage$ as activeLanguage">
        <div class="logo-container">
            <img
                [src]="activeLanguage === DEFAULT_LANG ? 'assets/images/logo-pl.svg' : 'assets/images/logo-en.svg'"
                [alt]="'landingPage.logoAltText' | transloco"
            />
        </div>
        <dtm-ui-language-select
            [languages]="languages"
            [selectedLanguage]="activeLanguage$ | ngrxPush"
            (languageChange)="setActiveLanguage($event)"
        >
        </dtm-ui-language-select>
    </div>
    <div class="content" dtmUiDisplayVersion>
        <div class="main-container">
            <div class="img-container"><img src="assets/images/register-image.svg" alt="" /></div>
            <div class="wizard-container">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<dtm-ui-cookies-popup *ngIf="isCookiesInfoVisible$ | ngrxPush" (cookiesHide)="hideCookiesInfo()"></dtm-ui-cookies-popup>
<dtm-ui-webpage-footer [activeLanguage]="activeLanguage$ | ngrxPush" (cookiesInfoSelect)="showCookiesInfo()"></dtm-ui-webpage-footer>
