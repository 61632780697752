import { HttpClient, HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Alpha3CountryCode, PhoneNumber } from "@dtm-frontend/shared/ui";
import { DateUtils, StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, noop, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ENROLLMENT_ENDPOINTS, EnrollmentEndpoints } from "./../enrollment.tokens";
import {
    EnrollmentErrorResponseBody,
    EnrollmentResponseBody,
    convertEnrollmentPayloadToEnrollmentRequestPayload,
} from "./enrollment-api.converters";
import { Enrollment, EnrollmentData, EnrollmentError, EnrollmentErrorType } from "./models";

@Injectable({
    providedIn: "root",
})
export class EnrollmentAPIService {
    constructor(private readonly httpClient: HttpClient, @Inject(ENROLLMENT_ENDPOINTS) private readonly endpoints: EnrollmentEndpoints) {}

    public enroll(enrollmentData: EnrollmentData, locale: string): Observable<Enrollment> {
        const enrollmentRequestPayload = convertEnrollmentPayloadToEnrollmentRequestPayload(enrollmentData);

        return this.httpClient
            .post<EnrollmentResponseBody>(this.endpoints.createEnrollment, {
                ...enrollmentRequestPayload,
                languageTag: locale,
            })
            .pipe(
                map((response) => response as Enrollment),
                catchError((errorResponse: HttpErrorResponse) => throwError(() => this.transformEnrollmentErrorResponse(errorResponse)))
            );
    }

    public resendConfirmationEmail(enrollment: Enrollment | undefined): Observable<void> {
        if (!enrollment) {
            return throwError(() => ({ type: EnrollmentErrorType.Unknown }));
        }

        return this.httpClient
            .post<EnrollmentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.resendConfirmationEmail, { id: enrollment.id }), {})
            .pipe(
                map(noop),
                catchError(() => throwError(() => ({ type: EnrollmentErrorType.Unknown })))
            );
    }

    public resendConfirmationCode(enrollment: Enrollment | undefined): Observable<void> {
        if (!enrollment) {
            return throwError(() => ({ type: EnrollmentErrorType.Unknown }));
        }

        return this.httpClient
            .post<EnrollmentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.resendConfirmationCode, { id: enrollment.id }), {
                phoneNumber: enrollment.phoneNumber,
            })
            .pipe(
                map(noop),
                catchError((errorResponse: HttpErrorResponse) => {
                    const retryAfterValue = errorResponse.headers.get("retry-after");

                    if (retryAfterValue) {
                        return throwError(() => ({
                            type: EnrollmentErrorType.ConfirmationCodeRateLimitReached,
                            date: new Date(retryAfterValue),
                        }));
                    }

                    return throwError(() => ({ type: EnrollmentErrorType.Unknown }));
                })
            );
    }

    public updatePhoneNumber(phoneNumber: PhoneNumber, enrollment: Enrollment | undefined, locale: string): Observable<Enrollment> {
        if (!enrollment) {
            return throwError(() => ({ type: EnrollmentErrorType.Unknown }));
        }

        return this.httpClient
            .put<EnrollmentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.updateEnrollment, { id: enrollment.id }), {
                phoneNumber,
                email: enrollment.email,
                languageTag: locale,
            })
            .pipe(
                map((response) => response as Enrollment),
                catchError((errorResponse: HttpErrorResponse) => {
                    const retryAfterValue = errorResponse.headers.get("retry-after");

                    if (retryAfterValue) {
                        return throwError(() => ({
                            type: EnrollmentErrorType.ConfirmationCodeRateLimitReached,
                            date: new Date(retryAfterValue),
                        }));
                    }

                    return throwError(() => this.transformEnrollmentErrorResponse(errorResponse));
                })
            );
    }

    public confirmEnrollment(confirmationCode: string, enrollmentId: string): Observable<Enrollment> {
        return this.httpClient
            .post<EnrollmentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.confirmEnrollment, { id: enrollmentId }), {
                code: confirmationCode,
            })
            .pipe(
                map((response) => response as Enrollment),
                catchError((errorResponse: HttpErrorResponse) =>
                    throwError(() => ({ type: this.transformRegistrationErrorResponse(errorResponse) }))
                )
            );
    }

    public register(
        password: string,
        confirmationCode: string,
        enrollmentId: string,
        firstName: string,
        lastName: string,
        nationality: Alpha3CountryCode,
        dateOfBirth: Date,
        locale: string
    ): Observable<void> {
        const dateOfBirthFormatted = DateUtils.getISOStringDate(dateOfBirth.toISOString());

        return this.httpClient
            .post<EnrollmentResponseBody>(StringUtils.replaceInTemplate(this.endpoints.createUser, { id: enrollmentId }), {
                firstName,
                lastName,
                nationality,
                dateOfBirth: dateOfBirthFormatted,
                password,
                smsCode: confirmationCode,
                languageTag: locale,
            })
            .pipe(
                map(noop),
                catchError((errorResponse: HttpErrorResponse) =>
                    throwError(() => ({ type: this.transformRegistrationErrorResponse(errorResponse) }))
                )
            );
    }

    private transformEnrollmentErrorResponse(errorResponse: HttpErrorResponse): EnrollmentError {
        const error = errorResponse as EnrollmentErrorResponseBody;

        if (!error.error?.fieldErrors) {
            return { type: EnrollmentErrorType.Unknown };
        }

        const emailFieldError = error.error.fieldErrors.find((fieldError) => fieldError.fieldName === "email");

        if (emailFieldError) {
            return {
                type: emailFieldError.code === "AlreadyUsed" ? EnrollmentErrorType.EmailAlreadyUsed : EnrollmentErrorType.InvalidEmail,
            };
        }

        const phoneNumberFieldError = error.error.fieldErrors.find((fieldError) => fieldError.fieldName === "phoneNumber");

        if (phoneNumberFieldError) {
            return {
                type:
                    phoneNumberFieldError.code === "AlreadyUsed"
                        ? EnrollmentErrorType.PhoneNumberAlreadyUsed
                        : EnrollmentErrorType.InvalidPhoneNumber,
            };
        }

        return { type: EnrollmentErrorType.Unknown };
    }

    private transformRegistrationErrorResponse(errorResponse: HttpErrorResponse): EnrollmentErrorType {
        switch (errorResponse.status) {
            case HttpStatusCode.BadRequest:
                return EnrollmentErrorType.InvalidConfirmationCode;
            case HttpStatusCode.NotFound:
                return EnrollmentErrorType.EnrollmentNotFound;
            default:
                return EnrollmentErrorType.Unknown;
        }
    }
}
