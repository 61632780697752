import { ChangeDetectorRef, Inject, Optional, Pipe, PipeTransform } from "@angular/core";
import { TRANSLOCO_LANG, TRANSLOCO_SCOPE, TranslocoPipe, TranslocoService } from "@ngneat/transloco";
import { LOCALE_CONFIG, LocaleConfig, TranslocoLocaleService } from "@ngneat/transloco-locale";
import { MaybeArray, TranslocoScope } from "@ngneat/transloco/lib/types";
import { LocalizeDatePipe } from "./localize-date.pipe";

@Pipe({
    name: "localizeFriendlyDate",
    pure: false,
})
export class LocalizeFriendlyDatePipe extends LocalizeDatePipe implements PipeTransform {
    private translocoPipe;

    constructor(
        public readonly cdr: ChangeDetectorRef,
        public readonly translocoLocaleService: TranslocoLocaleService,
        @Optional()
        @Inject(TRANSLOCO_SCOPE)
        private readonly providerScope: MaybeArray<TranslocoScope> | undefined,
        @Optional()
        @Inject(TRANSLOCO_LANG)
        private readonly providerLang: string | undefined,
        @Inject(LOCALE_CONFIG)
        private readonly localeConfiguration: LocaleConfig,
        private readonly translocoService: TranslocoService
    ) {
        super(translocoLocaleService, cdr, localeConfiguration);
        this.translocoPipe = new TranslocoPipe(translocoService, providerScope, providerLang, cdr);
    }

    public transform(value: Date): string {
        const missionDate = new Date(value);
        missionDate.setHours(0, 0, 0, 0);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0, 0, 0, 0);

        if (missionDate.getTime() === today.getTime()) {
            return this.translocoPipe.transform("dtmUiI18n.localizeFriendlyDate.todayDateLabel");
        } else if (missionDate.getTime() === tomorrow.getTime()) {
            return this.translocoPipe.transform("dtmUiI18n.localizeFriendlyDate.tomorrowDateLabel");
        }

        return super.transform(missionDate);
    }
}
