import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { EnrollmentError, EnrollmentErrorType } from "../../../services/models";
import { EnrollmentWizardStep } from "../wizard/enrollment-wizard.component";

interface EnrollmentWizardMessageComponentState {
    emailAddress: string | undefined;
    currentStep: EnrollmentWizardStep | undefined;
    enrollmentError: EnrollmentError | undefined;
}

@Component({
    selector: "dtm-lp-lib-enrollment-wizard-message",
    templateUrl: "./message.component.html",
    styleUrls: ["./message.component.scss"],
    providers: [LocalComponentStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnrollmentWizardMessageComponent {
    @Input() public set currentStep(value: EnrollmentWizardStep | undefined) {
        this.localStore.patchState({ currentStep: value });
    }
    @Input() public set emailAddress(value: string | undefined) {
        this.localStore.patchState({ emailAddress: value });
    }

    @Input() public set enrollmentError(value: EnrollmentError | undefined) {
        this.localStore.patchState({ enrollmentError: value });
    }

    @Output() public readonly resendEmail = new EventEmitter();
    @Output() public readonly goToRegistration = new EventEmitter();

    protected readonly emailAddress$ = this.localStore.selectByKey("emailAddress");
    protected readonly currentStep$ = this.localStore.selectByKey("currentStep");
    protected readonly enrollmentError$ = this.localStore.selectByKey("enrollmentError");
    protected readonly EnrollmentWizardStep = EnrollmentWizardStep;
    protected readonly EnrollmentErrorType = EnrollmentErrorType;

    constructor(private readonly localStore: LocalComponentStore<EnrollmentWizardMessageComponentState>) {
        this.localStore.setState({ emailAddress: undefined, currentStep: undefined, enrollmentError: undefined });
    }
}
