<ng-container [ngSwitch]="currentStep$ | ngrxPush" *ngrxLet="emailAddress$ as emailAddress">
    <div class="message-container" *ngSwitchCase="EnrollmentWizardStep.SuccessMessage" data-testid="enrollment-wizard-success-message">
        <div class="img-container"><img src="assets/images/email-success.svg" alt="" /></div>
        <h2>{{ "dtmLpLibEnrollment.message.successTitle" | transloco : { emailAddress } }}</h2>
        <p>{{ "dtmLpLibEnrollment.message.successMessage" | transloco }}</p>
        <p class="no-message-text">
            {{ "dtmLpLibEnrollment.message.noConfirmationEmailReceivedLinkText" | transloco }}
            <button class="button-link" type="button" (click)="resendEmail.emit()">
                {{ "dtmLpLibEnrollment.message.clickHereText" | transloco }}
            </button>
        </p>
    </div>

    <div class="message-container" *ngSwitchCase="EnrollmentWizardStep.ResendingSuccessMessage">
        <div class="img-container"><img src="assets/images/email-success.svg" alt="" /></div>
        <h2>{{ "dtmLpLibEnrollment.message.resendingTitle" | transloco : { emailAddress } }}</h2>
        <p>{{ "dtmLpLibEnrollment.message.resendingMessage" | transloco }}</p>
        <p class="no-message-text">
            {{ "dtmLpLibEnrollment.message.noConfirmationEmailReceivedLinkText" | transloco }}
            <button class="button-link" type="button" (click)="resendEmail.emit()">
                {{ "dtmLpLibEnrollment.message.clickHereText" | transloco }}
            </button>
        </p>
    </div>

    <div *ngSwitchCase="EnrollmentWizardStep.ResendingProgressMessage" class="centered-content">
        <mat-spinner diameter="40"></mat-spinner>
        <p>{{ "dtmLpLibEnrollment.message.resendingProgressMessage" | transloco }}</p>
    </div>

    <div *ngSwitchCase="EnrollmentWizardStep.SendingError" class="message-container">
        <div class="img-container"><img src="assets/images/mail-error.svg" alt="" /></div>
        <h2>{{ "dtmLpLibEnrollment.message.sendingErrorTitle" | transloco }}</h2>

        <p [ngSwitch]="enrollmentError?.type" *ngrxLet="enrollmentError$ as enrollmentError">
            <ng-container *ngSwitchCase="EnrollmentErrorType.EmailAlreadyUsed">{{
                "dtmLpLibEnrollment.message.emailAlreadyUsedErrorMessage" | transloco
            }}</ng-container>
            <ng-container *ngSwitchCase="EnrollmentErrorType.PhoneNumberAlreadyUsed">{{
                "dtmLpLibEnrollment.message.phoneNumberAlreadyUsed" | transloco
            }}</ng-container>
            <ng-container *ngSwitchDefault>{{ "dtmLpLibEnrollment.message.sendingErrorMessage" | transloco }}</ng-container>
        </p>

        <div class="bottom-button-container">
            <button class="button-secondary" type="button" (click)="goToRegistration.emit()">
                <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                {{ "dtmLpLibEnrollment.confirmationWizard.returnLabel" | transloco }}
            </button>
        </div>
    </div>
</ng-container>
