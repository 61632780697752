import { Injectable } from "@angular/core";
import { DatesDiffPipe, MILLISECONDS_IN_SECOND, RxjsUtils } from "@dtm-frontend/shared/utils";
import MessageFormat from "@messageformat/core";
import { HashMap, TranslocoService } from "@ngneat/transloco";
import { Observable, of } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { LANGUAGE_CONFIGURATION, LanguageDefinition } from "../i18n.models";
import { SYSTEM_TRANSLATION_SCOPE } from "../shared-i18n.tokens";

const DURATION_FORMAT_REGEX = /{{(\w+?)\|duration}}/;
const OVERRIDDEN_TRANSLATION_PREFIX = "dtmUiI18n.overriddenSystemTranslationsByKey";
const OVERRIDDEN_TRANSLATION_KEYS: string[] = [];

@Injectable()
export class TranslationHelperService {
    public readonly datePickerPlaceholder$ = this.transloco.events$.pipe(
        map((event) => event.payload.langName),
        RxjsUtils.filterFalsy(),
        startWith(undefined),
        map((activeLang) => {
            activeLang = activeLang ?? this.transloco.getActiveLang();
            const languageDefinition =
                LANGUAGE_CONFIGURATION.languageDefinitions.find((entry: LanguageDefinition) => entry.alpha2Code === activeLang) ?? null;

            if (languageDefinition) {
                return languageDefinition.dateFormatHint;
            }

            return "";
        })
    );

    constructor(private readonly transloco: TranslocoService) {}

    public waitForTranslation(key: string, params?: Record<string, unknown>): Observable<string> {
        return this.transloco.selectTranslate<string>(key, params).pipe(
            switchMap((existingTranslationOrKey: string) => {
                if (existingTranslationOrKey !== key) {
                    return of(existingTranslationOrKey);
                }

                return this.transloco.events$.pipe(
                    map(() => {
                        const translationOrKey = this.transloco.translate<string>(key, params);

                        return translationOrKey === key ? null : translationOrKey;
                    }),
                    RxjsUtils.filterFalsy()
                );
            })
        );
    }

    public getActiveLocale() {
        return LANGUAGE_CONFIGURATION.localeMapping[this.transloco.getActiveLang()];
    }

    public selectTranslation(key: string, defaultValue: string, params?: HashMap) {
        return this.transloco.selectTranslate<string>(key, params).pipe(
            switchMap((existingTranslationOrKey: string) => {
                if (existingTranslationOrKey !== key) {
                    return of(existingTranslationOrKey);
                }

                return this.transloco.events$.pipe(
                    map(() => {
                        const translationOrKey = this.transloco.translate<string>(key);

                        return translationOrKey === key ? defaultValue : translationOrKey;
                    })
                );
            })
        );
    }

    public tryTranslate(key: string, defaultValue?: string, params?: HashMap) {
        const translation = this.transloco.translate(key, params);

        return translation === key ? defaultValue : translation;
    }

    public selectSystemTranslation(key: string, params?: Record<string, unknown>): string {
        const systemTranslationKey = OVERRIDDEN_TRANSLATION_KEYS.includes(key)
            ? `${OVERRIDDEN_TRANSLATION_PREFIX}.${key}`
            : `${SYSTEM_TRANSLATION_SCOPE}.${key}`;

        const translationMap = this.transloco.getTranslation(this.transloco.getActiveLang());
        const translationTemplateValue: string = translationMap[systemTranslationKey];
        const durationFormatMatch = translationTemplateValue?.match(DURATION_FORMAT_REGEX);

        if (!durationFormatMatch?.[0]) {
            return this.transloco.translate(systemTranslationKey, params);
        }

        const argumentName = durationFormatMatch[1];
        const argumentValue = (params?.[argumentName] as number) ?? 0;
        const updatedTranslationTemplateValue = translationTemplateValue
            .replace(new RegExp(DURATION_FORMAT_REGEX, "g"), "{$1}")
            .replace(/{{(.+?)}}/g, "{$1}");
        const messageFormat = new MessageFormat(null);
        const newArgValue = new DatesDiffPipe(this.transloco).transform(
            new Date(),
            new Date(new Date().getTime() + Number(argumentValue) * MILLISECONDS_IN_SECOND)
        );

        const messageParser = messageFormat.compile(updatedTranslationTemplateValue);

        return messageParser({ ...params, [argumentName]: newArgValue });
    }
}
