import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { EnrollmentActions } from "@dtm-frontend/landing-page-lib/enrollment";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Actions, ofActionCompleted, ofActionDispatched } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { LandingPageEnvironment } from "../../environments/environment.model";
import { dynamicEnvironment } from "./../../environments/environment";

@UntilDestroy()
@Injectable({
    providedIn: "root",
})
export class ActionsHandler {
    constructor(
        private readonly actions$: Actions,
        @Inject(DOCUMENT) private readonly document: Document,
        @Inject(dynamicEnvironment.injectionToken) private readonly environment: LandingPageEnvironment
    ) {
        this.handleEnrollmentGoToFAQPage();
        this.handleEnrollmentGoToUAVRulesPage();
        this.handleEnrollmentGoToLoginPage();
        this.handleEnrollmentShowTermsOfService();
        this.handleEnrollmentGoToApp();
    }

    private handleEnrollmentGoToFAQPage() {
        this.actions$
            .pipe(
                ofActionDispatched(EnrollmentActions.GoToFAQPage),
                tap(() => this.document.defaultView?.open(this.environment.routes.faqPageUrl)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleEnrollmentGoToUAVRulesPage() {
        this.actions$
            .pipe(
                ofActionDispatched(EnrollmentActions.GoToUAVRulesPage),
                tap(() => this.document.defaultView?.open(this.environment.routes.uavRulesPageUrl)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleEnrollmentGoToLoginPage() {
        this.actions$
            .pipe(
                ofActionDispatched(EnrollmentActions.GoToLoginPage, AuthActions.GoToLoginPage),
                tap(() => this.document.location.assign(this.environment.loginPageUrl)),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleEnrollmentShowTermsOfService() {
        this.actions$
            .pipe(
                ofActionDispatched(EnrollmentActions.ShowTermsOfService),
                tap(() => console.log("show terms of service") /* TODO: show terms of service dialog + test */),
                untilDestroyed(this)
            )
            .subscribe();
    }

    private handleEnrollmentGoToApp() {
        this.actions$
            .pipe(
                ofActionCompleted(EnrollmentActions.GoToApp),
                tap(() => this.document.location.assign(this.environment.loginPageUrl)),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
