<ng-container *ngrxLet="{ currentStep: currentStep$, error: error$ } as vm">
    <ng-container [ngSwitch]="vm.error?.type">
        <div class="message-container" *ngSwitchCase="EnrollmentErrorType.Unknown">
            <div class="img-container"><img src="assets/images/mail-error.svg" alt="" /></div>
            <h2>{{ "dtmLpLibEnrollment.confirmationWizard.emailConfirmationErrorTitle" | transloco }}</h2>
            <p>{{ "dtmLpLibEnrollment.confirmationWizard.emailConfirmationErrorMessage" | transloco }}</p>
        </div>
        <div class="message-container" *ngSwitchCase="EnrollmentErrorType.EnrollmentNotFound">
            <div class="img-container"><img src="assets/images/mail-error.svg" alt="" /></div>
            <h2>{{ "dtmLpLibEnrollment.confirmationWizard.emailConfirmationNotFoundErrorTitle" | transloco }}</h2>
            <p>{{ "dtmLpLibEnrollment.confirmationWizard.emailConfirmationNotFoundErrorMessage" | transloco }}</p>
            <div class="bottom-container">
                <button class="button-secondary" type="button" routerLink="/">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>{{ "dtmLpLibEnrollment.confirmationWizard.returnLabel" | transloco }}
                </button>
            </div>
        </div>

        <ng-container *ngSwitchDefault [ngTemplateOutlet]="stepTemplate"> </ng-container>
    </ng-container>

    <ng-template #stepTemplate>
        <ng-container [ngSwitch]="vm.currentStep">
            <div *ngSwitchCase="ConfirmationWizardStep.EmailConfirmationProgress" class="centered-content">
                <mat-spinner diameter="40"></mat-spinner>
                <p>{{ "dtmLpLibEnrollment.confirmationWizard.emailConfirmationProgressMessage" | transloco }}</p>
            </div>
            <dtm-lp-lib-confirmation-wizard-change-phone-form-step
                *ngSwitchCase="ConfirmationWizardStep.ChangePhoneForm"
                (phoneNumberUpdate)="updatePhoneNumber($event)"
                (confirmationCodeResend)="resendConfirmationCode()"
                (back)="goToStep(ConfirmationWizardStep.RegistrationForm)"
                [isProcessing]="isProcessing$ | ngrxPush"
                [enrollmentError]="vm.error"
                [enrollment]="enrollment$ | ngrxPush"
            ></dtm-lp-lib-confirmation-wizard-change-phone-form-step>

            <dtm-lp-lib-confirmation-wizard-confirmation-form-step-component
                *ngSwitchDefault
                (registrationComplete)="register($event)"
                (noConfirmationCodeRedirect)="goToStep(ConfirmationWizardStep.ChangePhoneForm)"
                [isProcessing]="isProcessing$ | ngrxPush"
                [registrationError]="vm.error"
                [enrollment]="enrollment$ | ngrxPush"
            ></dtm-lp-lib-confirmation-wizard-confirmation-form-step-component>
        </ng-container>
    </ng-template>
</ng-container>
