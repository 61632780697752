import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ConfirmationWizardComponent } from "./components/confirmation-wizard/wizard/confirmation-wizard.component";
import { EnrollmentWizardComponent } from "./components/enrollment-wizard/wizard/enrollment-wizard.component";
import { CONFIRMATION_CODE_ROUTE_PARAM_NAME, ConfirmationResolver, ENROLLMENT_ID_ROUTE_PARAM_NAME } from "./services/confirmation.resolver";

const routes: Routes = [
    {
        path: "",
        component: EnrollmentWizardComponent,
    },
    {
        path: `enrollment-confirmation/:${ENROLLMENT_ID_ROUTE_PARAM_NAME}/:${CONFIRMATION_CODE_ROUTE_PARAM_NAME}`,
        component: ConfirmationWizardComponent,
        resolve: {
            init: ConfirmationResolver,
        },
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [ConfirmationResolver],
})
export class EnrollmentRoutingModule {}
