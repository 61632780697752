import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Observable, map } from "rxjs";
import { filter } from "rxjs/operators";
import { DialogService } from "../../services/dialog/dialog.service";
import { ButtonTheme, ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";

interface EditableBoxComponentState {
    label: string | undefined;
    saveButtonLabel: string | undefined;
    removalConfirmationTitle: string | undefined;
    isEditMode: boolean;
    isEditButtonVisible: boolean;
    canLeaveEditModeOnSave: boolean;
    isRemovable: boolean;
    isSaveButtonDisabled: boolean;
}

@UntilDestroy()
@Component({
    selector: "dtm-ui-editable-box[label]",
    templateUrl: "./editable-box.component.html",
    styleUrls: ["./editable-box.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EditableBoxComponent {
    @Input() public set label(value: string | undefined) {
        this.localStore.patchState({ label: value });
    }

    @Input() public set saveButtonLabel(value: string | undefined) {
        this.localStore.patchState({ saveButtonLabel: value });
    }

    @Input() public set removalConfirmationTitle(value: string | undefined) {
        this.localStore.patchState({ removalConfirmationTitle: value });
    }

    @Input() public set canLeaveEditModeOnSave(value: boolean | undefined) {
        this.localStore.patchState({ canLeaveEditModeOnSave: value ?? true });
    }

    @Input() public set isRemovable(value: boolean | undefined) {
        this.localStore.patchState({ isRemovable: value ?? false });
    }

    @Input() public set isSaveButtonDisabled(value: boolean | undefined) {
        this.localStore.patchState({ isSaveButtonDisabled: value ?? false });
    }

    @Input() public set isEditButtonVisible(value: boolean | undefined) {
        this.localStore.patchState({ isEditButtonVisible: value ?? true });
    }

    @Input() public set isEditModeOpen(value: boolean | undefined) {
        this.localStore.patchState({ isEditMode: value ?? false });
    }

    @Output() public save = new EventEmitter<void>();
    @Output() public remove = new EventEmitter<void>();
    @Output() public closeEdition = new EventEmitter<void>();
    @Output() public editModeStateChanged = new EventEmitter<boolean>();

    public readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    public readonly isEditButtonVisible$ = this.localStore.selectByKey("isEditButtonVisible");
    public readonly isRemovable$ = this.localStore.selectByKey("isRemovable");
    public readonly label$ = this.localStore.selectByKey("label").pipe(
        RxjsUtils.filterFalsy(),
        map((label) => label.trim())
    );
    public readonly saveButtonLabel$ = this.localStore.selectByKey("saveButtonLabel");
    public readonly isSaveButtonDisabled$ = this.localStore.selectByKey("isSaveButtonDisabled");

    constructor(
        private readonly localStore: LocalComponentStore<EditableBoxComponentState>,
        private readonly dialogService: DialogService,
        private readonly transloco: TranslocoService
    ) {
        this.localStore.setState({
            label: undefined,
            saveButtonLabel: undefined,
            removalConfirmationTitle: undefined,
            isEditMode: false,
            isEditButtonVisible: true,
            canLeaveEditModeOnSave: true,
            isRemovable: false,
            isSaveButtonDisabled: false,
        });
    }

    public setEditMode(value: boolean) {
        this.localStore.patchState({ isEditMode: value });

        if (!value) {
            this.closeEdition.emit();
        }

        this.editModeStateChanged.emit(value);
    }

    public saveChanges(): void {
        this.save.emit();

        if (this.localStore.selectSnapshotByKey("canLeaveEditModeOnSave")) {
            this.setEditMode(false);
        }
    }

    public tryRemove(): void {
        this.confirmRemoval()
            .pipe(filter(Boolean), untilDestroyed(this))
            .subscribe(() => {
                this.remove.emit();
            });
    }

    private confirmRemoval(): Observable<boolean> {
        const title =
            this.localStore.selectSnapshotByKey("removalConfirmationTitle") ??
            this.transloco.translate("dtmUi.editableBox.confirmRemovalDialog.titleText");

        return this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: title,
                    confirmationText: this.transloco.translate("dtmUi.editableBox.confirmRemovalDialog.contentText"),
                    declineButtonLabel: this.transloco.translate("dtmUi.editableBox.confirmRemovalDialog.cancelButtonLabel"),
                    confirmButtonLabel: this.transloco.translate("dtmUi.editableBox.confirmRemovalDialog.confirmButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed();
    }
}
