import { PhoneNumber } from "@dtm-frontend/shared/ui";

export interface EnrollmentData {
    email: string;
    phoneNumber: string;
    isMinAge: boolean;
    areTermsOfServiceAccepted: boolean;
}

export interface Enrollment {
    id: string;
    phoneNumber: PhoneNumber;
    email: string;
}

export interface ConfirmationCodeRateLimitReachedEnrollmentError {
    type: EnrollmentErrorType.ConfirmationCodeRateLimitReached;
    date: Date;
}

export type EnrollmentError = { type: EnrollmentErrorType } | ConfirmationCodeRateLimitReachedEnrollmentError;
export enum EnrollmentErrorType {
    Unknown = "Unknown",
    InvalidEmail = "InvalidEmail",
    EmailAlreadyUsed = "EmailAlreadyUsed",
    InvalidPhoneNumber = "InvalidPhoneNumber",
    PhoneNumberAlreadyUsed = "PhoneNumberAlreadyUsed",
    ConfirmationCodeRateLimitReached = "ConfirmationCodeRateLimitReached",
    EnrollmentNotFound = "EnrollmentNotFound",
    InvalidConfirmationCode = "InvalidConfirmationCode",
}

export interface FieldErrorItem {
    fieldName: "email" | "phoneNumber";
    code: "NotEmpty" | "Pattern" | "AlreadyUsed";
}
