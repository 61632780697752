<dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
    <div class="main-container" *ngIf="enrollment$ | ngrxPush as enrollment">
        <div class="img-container">
            <img src="assets/images/phone-success.svg" alt="" />
        </div>
        <h6>{{ "dtmLpLibEnrollment.changePhoneFormStep.changePhoneNumberFormInfoMessage" | transloco }}</h6>
        <p>{{ "dtmLpLibEnrollment.changePhoneFormStep.resendMessageCodeInfoMessage" | transloco }}</p>
        <form [formGroup]="changePhoneFormGroup" (submit)="trySubmit($event)" *ngrxLet="isProcessing$ as isProcessing">
            <dtm-ui-phone-number-field
                required
                formControlName="phoneNumber"
                dtmUiMarkValueAccessorControlsAsTouched
                [searchPlaceholder]="'dtmLpLibEnrollment.phoneNumber.countrySearchPlaceholder' | transloco"
            >
                <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'invalidNumber'">
                    {{ "dtmLpLibEnrollment.phoneNumber.invalidPhoneNumberErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'invalidNumberType'">
                    {{ "dtmLpLibEnrollment.phoneNumber.invalidPhoneNumberTypeErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'required'">
                    {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'alreadyUsed'">
                    {{ "dtmLpLibEnrollment.phoneNumber.alreadyUsedPhoneNumberErrorHint" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'resendConfirmationCode'">
                    {{
                        "dtmLpLibEnrollment.changePhoneFormStep.resendConfirmationCodeButtonWithRateErrorHint"
                            | transloco : { rateLimitDateTimeLeft }
                    }}
                </div>
            </dtm-ui-phone-number-field>

            <div class="actions">
                <button class="button-primary" type="submit" [ngClass]="{ processing: isProcessing }">
                    <mat-spinner diameter="20" *ngIf="isProcessing"></mat-spinner>
                    <span>
                        {{ "dtmLpLibEnrollment.changePhoneFormStep.resendConfirmationCodeButtonLabel" | transloco }}
                    </span>
                </button>
                <button class="button-secondary" type="button" (click)="back.emit()">
                    {{ "dtmLpLibEnrollment.changePhoneFormStep.cancelButtonLabel" | transloco }}
                </button>
            </div>
        </form>
    </div>
</dtm-ui-loader-container>
