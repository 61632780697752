import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngxs/store";
import { EnrollmentActions } from "../state/enrollment.actions";

export const ENROLLMENT_ID_ROUTE_PARAM_NAME = "enrollmentId";
export const CONFIRMATION_CODE_ROUTE_PARAM_NAME = "confirmationCode";

@Injectable()
export class ConfirmationResolver {
    constructor(private readonly store: Store) {}

    public async resolve(route: ActivatedRouteSnapshot): Promise<void> {
        const enrollmentId = route.paramMap.get(ENROLLMENT_ID_ROUTE_PARAM_NAME) ?? "";
        const confirmationCode = route.paramMap.get(CONFIRMATION_CODE_ROUTE_PARAM_NAME) ?? "";

        this.store.dispatch(new EnrollmentActions.ConfirmEnrollment(enrollmentId, confirmationCode));
    }
}
