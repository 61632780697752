import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { InvalidFormScrollableDirective, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { tap } from "rxjs/operators";
import { EnrollmentData, EnrollmentError, EnrollmentErrorType } from "../../../services/models";

interface EnrollmentWizardFormStepComponentState {
    isProcessing: boolean;
    enrollmentError: EnrollmentError | undefined;
}

interface EnrollmentForm {
    email: FormControl<string>;
    phoneNumber: FormControl<string>;
    isMinAge: FormControl<boolean>;
    areTermsOfServiceAccepted: FormControl<boolean>;
}

@UntilDestroy()
@Component({
    selector: "dtm-lp-lib-enrollment-wizard-form-step",
    templateUrl: "./form-step.component.html",
    styleUrls: ["./form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class EnrollmentWizardFormStepComponent {
    @Output() public enroll = new EventEmitter<EnrollmentData>();
    @Output() public login = new EventEmitter();
    @Input() public set isProcessing(value: boolean | undefined) {
        this.localStore.patchState({ isProcessing: !!value });
    }
    @Input() public set enrollmentError(value: EnrollmentError | undefined) {
        this.localStore.patchState({ enrollmentError: value });
    }

    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly emailFormControl = new FormControl<string>("", {
        validators: [Validators.required, Validators.email],
        nonNullable: true,
    });
    protected readonly phoneNumberFormControl = new FormControl<string>("", {
        validators: requiredValidForSmsPhoneNumberValidator,
        nonNullable: true,
    });
    protected readonly minAgeFormControl = new FormControl<boolean>(false, {
        validators: Validators.requiredTrue,
        nonNullable: true,
    });
    protected readonly termsFormControl = new FormControl<boolean>(false, {
        validators: Validators.requiredTrue,
        nonNullable: true,
    });
    protected readonly enrollmentFormGroup = new FormGroup<EnrollmentForm>({
        email: this.emailFormControl,
        phoneNumber: this.phoneNumberFormControl,
        isMinAge: this.minAgeFormControl,
        areTermsOfServiceAccepted: this.termsFormControl,
    });

    constructor(
        private readonly localStore: LocalComponentStore<EnrollmentWizardFormStepComponentState>,
        @Inject(TERMS_OF_USE_URL) protected readonly termsOfUseUrl: string
    ) {
        this.localStore.setState({ isProcessing: false, enrollmentError: undefined });

        this.isProcessing$
            .pipe(
                tap((isProcessing) => (isProcessing ? this.enrollmentFormGroup.disable() : this.enrollmentFormGroup.enable())),
                untilDestroyed(this)
            )
            .subscribe();
        this.localStore
            .selectByKey("enrollmentError")
            .pipe(
                tap((error) => {
                    if (!error) {
                        return;
                    }
                    if (error.type === EnrollmentErrorType.EmailAlreadyUsed || error.type === EnrollmentErrorType.InvalidEmail) {
                        this.emailFormControl.setErrors({
                            alreadyUsed: error.type === EnrollmentErrorType.EmailAlreadyUsed,
                            invalid: error.type === EnrollmentErrorType.InvalidEmail,
                        });
                        this.emailFormControl.markAsTouched();
                    } else if (
                        error.type === EnrollmentErrorType.PhoneNumberAlreadyUsed ||
                        error.type === EnrollmentErrorType.InvalidPhoneNumber
                    ) {
                        this.phoneNumberFormControl.setErrors({
                            alreadyUsed: error.type === EnrollmentErrorType.PhoneNumberAlreadyUsed,
                            invalid: error.type === EnrollmentErrorType.InvalidPhoneNumber,
                        });
                        this.phoneNumberFormControl.markAsTouched();
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public trySubmit(event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();

        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");

        if (this.enrollmentFormGroup.invalid || isProcessing) {
            this.enrollmentFormGroup.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.enroll.emit(this.enrollmentFormGroup.getRawValue());
    }
}
