import { ChangeDetectionStrategy, Component } from "@angular/core";
import { UIActions, UIState } from "@dtm-frontend/shared/ui";
import { DEFAULT_LANG, LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { APPROX_DAYS_IN_YEAR, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { CookieService } from "ngx-cookie-service";

interface AppComponentState {
    isCookiesInfoVisible: boolean;
}

const LANDING_PAGE_COOKIE = "LANDING_PAGE_COOKIE";
const LANDING_PAGE_COOKIE_VALUE = "true";

@Component({
    selector: "dtm-lp-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AppComponent {
    protected readonly activeLanguage$ = this.store.select(UIState.activeLanguage).pipe(RxjsUtils.filterFalsy());
    protected readonly isCookiesInfoVisible$ = this.localStore.selectByKey("isCookiesInfoVisible");

    protected readonly languages = LANGUAGE_CONFIGURATION.languageDefinitions;
    protected readonly DEFAULT_LANG = DEFAULT_LANG;

    constructor(
        private readonly store: Store,
        private readonly localStore: LocalComponentStore<AppComponentState>,
        private readonly cookieService: CookieService
    ) {
        localStore.setState({ isCookiesInfoVisible: !cookieService.check(LANDING_PAGE_COOKIE) });
    }

    protected setActiveLanguage(language: LanguageCode) {
        this.store.dispatch(new UIActions.SetActiveLanguage(language));
    }

    protected hideCookiesInfo() {
        this.cookieService.set(LANDING_PAGE_COOKIE, LANDING_PAGE_COOKIE_VALUE, { expires: APPROX_DAYS_IN_YEAR });
        this.localStore.patchState({ isCookiesInfoVisible: false });
    }

    protected showCookiesInfo() {
        this.localStore.patchState({ isCookiesInfoVisible: true });
    }
}
