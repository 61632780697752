import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatest } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { EnrollmentError, EnrollmentErrorType } from "../../../services/models";
import { EnrollmentActions } from "../../../state/enrollment.actions";
import { EnrollmentState } from "../../../state/enrollment.state";
import { RegistrationFormData } from "../confirmation-form-step/confirmation-form-step.component";

export enum ConfirmationWizardStep { // TODO: maybe we should use XState or other fsm mechanism?
    EmailConfirmationProgress = "EmailConfirmationProgress",
    RegistrationForm = "RegistrationForm",
    EmailConfirmationError = "EmailConfirmationError",
    ChangePhoneForm = "ChangePhoneForm",
}

interface ConfirmationWizardComponentState {
    currentStep: ConfirmationWizardStep;
}

@UntilDestroy()
@Component({
    selector: "dtm-lp-lib-confirmation-wizard",
    templateUrl: "./confirmation-wizard.component.html",
    styleUrls: ["./confirmation-wizard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConfirmationWizardComponent implements OnInit {
    @Input() public set currentStep(value: ConfirmationWizardStep) {
        this.localStore.patchState({ currentStep: value });
    }

    public ConfirmationWizardStep = ConfirmationWizardStep;

    public readonly currentStep$ = this.localStore.selectByKey("currentStep");
    public readonly isProcessing$ = this.store.select(EnrollmentState.isProcessing);
    public readonly enrollment$ = this.store.select(EnrollmentState.enrollment);
    public readonly error$ = this.store.select(EnrollmentState.error);
    public readonly EnrollmentErrorType = EnrollmentErrorType;

    constructor(private readonly localStore: LocalComponentStore<ConfirmationWizardComponentState>, private readonly store: Store) {
        this.localStore.setState({
            currentStep: ConfirmationWizardStep.EmailConfirmationProgress,
        });
    }

    public ngOnInit() {
        combineLatest([this.error$, this.enrollment$, this.currentStep$])
            .pipe(
                filter(([error, enrollment]) => !!error || !!enrollment),
                tap(([error, , currentStep]) => {
                    if (error) {
                        return;
                    }

                    if (currentStep !== ConfirmationWizardStep.EmailConfirmationProgress) {
                        return;
                    }
                    this.localStore.patchState({ currentStep: ConfirmationWizardStep.RegistrationForm });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public goToStep(newStep: ConfirmationWizardStep) {
        this.localStore.patchState({ currentStep: newStep });
    }

    public register(data: RegistrationFormData) {
        this.store.dispatch(
            new EnrollmentActions.Register(
                data.password,
                data.confirmationCode,
                data.enrollmentId,
                data.firstName,
                data.lastName,
                data.nationality,
                data.dateOfBirth
            )
        );
    }

    public updatePhoneNumber(newPhoneNumber: PhoneNumber) {
        this.store
            .dispatch(new EnrollmentActions.UpdatePhoneNumber(newPhoneNumber))
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(EnrollmentState.error) as EnrollmentError;

                    if (!error) {
                        return this.goToStep(ConfirmationWizardStep.RegistrationForm);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    public resendConfirmationCode() {
        this.store
            .dispatch(new EnrollmentActions.ResendConfirmationCode())
            .pipe(
                tap(() => {
                    const error = this.store.selectSnapshot(EnrollmentState.error) as EnrollmentError;

                    if (!error) {
                        return this.goToStep(ConfirmationWizardStep.RegistrationForm);
                    }
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
