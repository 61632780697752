import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { SharedAuthModule } from "@dtm-frontend/shared/auth";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, TranslationHelperService, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TERMS_OF_USE_URL } from "@dtm-frontend/shared/utils";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ConfirmationWizardChangePhoneFormStepComponent } from "./components/confirmation-wizard/change-phone-form-step/change-phone-form-step.component";
import { ConfirmationWizardConfirmationFormStepComponent } from "./components/confirmation-wizard/confirmation-form-step/confirmation-form-step.component";
import { ConfirmationWizardComponent } from "./components/confirmation-wizard/wizard/confirmation-wizard.component";
import { EnrollmentWizardFormStepComponent } from "./components/enrollment-wizard/form-step/form-step.component";
import { EnrollmentWizardMessageComponent } from "./components/enrollment-wizard/message/message.component";
import { EnrollmentWizardComponent } from "./components/enrollment-wizard/wizard/enrollment-wizard.component";
import { EnrollmentRoutingModule } from "./enrollment-routing.module";
import { EnrollmentAPIService } from "./services/enrollment-api.service";
import { EnrollmentState } from "./state/enrollment.state";

@NgModule({
    declarations: [
        EnrollmentWizardComponent,
        EnrollmentWizardFormStepComponent,
        EnrollmentWizardMessageComponent,
        ConfirmationWizardComponent,
        ConfirmationWizardConfirmationFormStepComponent,
        ConfirmationWizardChangePhoneFormStepComponent,
    ],
    imports: [
        CommonModule,
        EnrollmentRoutingModule,
        HttpClientModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatNativeDateModule,
        MatProgressSpinnerModule,
        NgxsModule.forFeature([EnrollmentState]),
        LetModule,
        PushModule,
        ReactiveFormsModule,
        SharedAuthModule,
        SharedUiModule,
        SharedI18nModule,
    ],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "dtmLpLibEnrollment",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
        {
            provide: EnrollmentAPIService,
            useValue: undefined,
        },
        TranslationHelperService,
    ],
    exports: [
        EnrollmentWizardComponent,
        EnrollmentWizardFormStepComponent,
        EnrollmentWizardMessageComponent,
        ConfirmationWizardComponent,
        ConfirmationWizardConfirmationFormStepComponent,
        ConfirmationWizardChangePhoneFormStepComponent,
    ],
})
export class EnrollmentModule {
    public static forRoot(): ModuleWithProviders<EnrollmentModule> {
        return {
            ngModule: EnrollmentModule,
            providers: [
                {
                    provide: EnrollmentAPIService,
                    useClass: EnrollmentAPIService,
                },
            ],
        };
    }

    public static forTest(): ModuleWithProviders<EnrollmentModule> {
        return {
            ngModule: EnrollmentModule,
            providers: [
                {
                    provide: EnrollmentAPIService,
                    useValue: null,
                },
                {
                    provide: TERMS_OF_USE_URL,
                    useValue: "",
                },
            ],
        };
    }
}
