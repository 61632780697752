import { PhoneNumber } from "@dtm-frontend/shared/ui";
import { EnrollmentData, FieldErrorItem } from "./models";

interface EnrollmentRequestPayload {
    email: string;
    phoneNumber: string;
    userMinAgeStatement: boolean;
    userAcceptsTermsOfService: boolean;
}

export interface EnrollmentResponseBody {
    id: string | undefined;
    phoneNumber: PhoneNumber;
    email: string;
}

export interface FieldErrorsResponseBody {
    fieldErrors: FieldErrorItem[] | undefined;
}
export interface EnrollmentErrorResponseBody {
    error: FieldErrorsResponseBody;
}

export function convertEnrollmentPayloadToEnrollmentRequestPayload(payload: EnrollmentData): EnrollmentRequestPayload {
    return {
        email: payload.email,
        phoneNumber: payload.phoneNumber,
        userAcceptsTermsOfService: payload.areTermsOfServiceAccepted,
        userMinAgeStatement: payload.isMinAge,
    };
}
