<form [formGroup]="enrollmentFormGroup" (submit)="trySubmit($event)" *ngrxLet="isProcessing$ as isProcessing">
    <div class="content scroll-shadows" dtmUiInvalidFormScrollable>
        <h1>{{ "dtmLpLibEnrollment.formStep.createAccountText" | transloco }}</h1>
        <dtm-ui-input-field>
            <label for="dtm-lp-lib-enrollment-wizard-form-step.email">
                {{ "dtmLpLibEnrollment.formStep.emailInputLabel" | transloco }}
            </label>
            <input
                name="email"
                inputmode="email"
                data-testid="email-input"
                [formControl]="emailFormControl"
                type="email"
                autocomplete="email"
                id="dtm-lp-lib-enrollment-wizard-form-step.email"
                matInput
            />
            <div class="field-error" *dtmUiFieldHasError="emailFormControl; name: ['email', 'invalid']">
                {{ "dtmLpLibEnrollment.formStep.invalidEmailErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="emailFormControl; name: 'required'">
                {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="emailFormControl; name: 'alreadyUsed'">
                {{ "dtmLpLibEnrollment.formStep.alreadyUsedEmailErrorHint" | transloco }}
            </div>
        </dtm-ui-input-field>
        <dtm-ui-phone-number-field
            data-testid="phone-input"
            [formControl]="phoneNumberFormControl"
            [searchPlaceholder]="'dtmLpLibEnrollment.phoneNumber.countrySearchPlaceholder' | transloco"
        >
            <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'invalidNumber'">
                {{ "dtmLpLibEnrollment.phoneNumber.invalidPhoneNumberErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'invalidNumberType'">
                {{ "dtmLpLibEnrollment.phoneNumber.invalidPhoneNumberTypeErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'required'">
                {{ "dtmLpLibEnrollment.requiredFieldErrorHint" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="phoneNumberFormControl; name: 'alreadyUsed'">
                {{ "dtmLpLibEnrollment.phoneNumber.alreadyUsedPhoneNumberErrorHint" | transloco }}
            </div>
        </dtm-ui-phone-number-field>

        <div>
            <dtm-ui-checkbox-field [formControl]="termsFormControl" required class="checkbox-with-button">
                <span class="label">{{ "dtmLpLibEnrollment.formStep.acceptLabel" | transloco }}</span>
                <a
                    [href]="termsOfUseUrl"
                    rel="noopener noreferrer"
                    target="_blank"
                    class="button-link custom"
                    (click)="$event.stopPropagation()"
                    >{{ "dtmLpLibEnrollment.formStep.termsOfUseLabel" | transloco }}</a
                >
            </dtm-ui-checkbox-field>

            <dtm-ui-checkbox-field [formControl]="minAgeFormControl" required>
                <span class="label">{{ "dtmLpLibEnrollment.formStep.ageConfirmationLabel" | transloco }}</span>
            </dtm-ui-checkbox-field>
            <dtm-ui-form-errors class="preserve-error-space">
                <div
                    class="field-error"
                    *ngIf="
                        (termsFormControl.touched && termsFormControl.invalid) || (minAgeFormControl.touched && minAgeFormControl.invalid)
                    "
                >
                    {{ "dtmLpLibEnrollment.formStep.termsAndAgeConfirmationError" | transloco }}
                </div>
            </dtm-ui-form-errors>
        </div>
    </div>
    <div class="button-container">
        <button
            class="button-primary"
            type="submit"
            [ngClass]="{ processing: isProcessing }"
            [disabled]="isProcessing"
            data-testid="register-button"
        >
            <mat-spinner diameter="20" *ngIf="isProcessing"></mat-spinner>
            <span>
                {{ "dtmLpLibEnrollment.formStep.registerButtonLabel" | transloco }}
            </span>
        </button>
    </div>
    <div class="bottom-container">
        {{ "dtmLpLibEnrollment.formStep.existingAccountText" | transloco }}
        <button class="button-link" type="button" (click)="login.emit()">
            {{ "dtmLpLibEnrollment.formStep.loginText" | transloco }}
        </button>
    </div>
</form>
