import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnInit,
    TemplateRef,
    ViewChild,
    forwardRef,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { BYTES_IN_MEGABYTE, FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ContentChange } from "ngx-quill/lib/quill-editor.component";
import { ToastrService } from "ngx-toastr";
import QuillType from "quill";
import { distinctUntilChanged } from "rxjs/operators";

interface QuillEditorComponentState {
    hasErrors: boolean;
    placeholder: string;
    isQuillToolbarExtended: boolean;
}

const MAX_PICTURE_SIZE_IN_MEGABYTES = BYTES_IN_MEGABYTE / BYTES_IN_MEGABYTE;
const MAX_PICTURE_SIZE_IN_BYTES = BYTES_IN_MEGABYTE;
const ACCEPTED_IMAGE_TYPES = ["image/jpeg", "image/png"];

@UntilDestroy()
@Component({
    selector: "dtm-ui-quill-editor",
    templateUrl: "./quill-editor.component.html",
    styleUrls: ["./quill-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => QuillEditorComponent),
            multi: true,
        },
    ],
})
export class QuillEditorComponent implements OnInit, ControlValueAccessor {
    @Input() public set hasError(value: boolean | undefined) {
        this.localStore.patchState({ hasErrors: coerceBooleanProperty(value) });
    }
    @Input() public set placeholder(value: string | undefined) {
        this.localStore.patchState({ placeholder: value ?? "" });
    }
    @Input() public set isQuillToolbarExtended(value: BooleanInput) {
        this.localStore.patchState({ isQuillToolbarExtended: coerceBooleanProperty(value) });
    }
    @ViewChild("fileInputField") protected fileInputField!: ElementRef<HTMLInputElement>;

    @ContentChild("customToolbarTemplate") protected customToolbarTemplate: TemplateRef<HTMLElement> | undefined;

    protected quillEditorRef!: QuillType;
    protected ACCEPTED_IMAGE_TYPES = ACCEPTED_IMAGE_TYPES;
    protected control = new FormControl<string | null>(null);
    protected hasErrors$ = this.localStore.selectByKey("hasErrors");
    protected placeholder$ = this.localStore.selectByKey("placeholder");
    protected isQuillToolbarExtended$ = this.localStore.selectByKey("isQuillToolbarExtended");

    private propagateTouch = FunctionUtils.noop;
    private propagateChange: (value: string | null) => void = FunctionUtils.noop;

    constructor(
        private readonly localStore: LocalComponentStore<QuillEditorComponentState>,
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService
    ) {
        localStore.setState({ hasErrors: false, placeholder: "", isQuillToolbarExtended: false });
    }

    public ngOnInit() {
        this.control.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
            this.propagateChange(value);
        });
    }

    public registerOnChange(fn: (value: string | null) => void): void {
        this.propagateChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.propagateTouch = fn;
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    public writeValue(value: string | null): void {
        this.control.setValue(value, { emitEvent: false });
    }

    protected handleEditorCreation(editorInstance: QuillType) {
        this.quillEditorRef = editorInstance;
        const toolbar = editorInstance.getModule("toolbar");
        toolbar.addHandler("image", this.imageHandler.bind(this));
    }

    protected updateControl(editorInstance: ContentChange) {
        if (this.control.value === editorInstance.html) {
            return;
        }
        this.control.setValue(editorInstance.html);

        // NOTE: value is set to "" to allow uploading the same file more than once
        this.fileInputField.nativeElement.value = "";
    }

    protected markAsTouched() {
        this.propagateTouch();
    }

    protected setHasErrors(hasErrors: boolean) {
        this.localStore.patchState({ hasErrors });
    }

    private imageHandler() {
        const input = this.fileInputField.nativeElement;

        input.onchange = () => {
            const inputFiles = input.files ?? [];
            if (!inputFiles.length) {
                return;
            }

            const file = inputFiles[0];
            if (!/^image\/png/.test(file.type) && !/^image\/jpeg/.test(file.type)) {
                this.toastrService.error(this.translocoService.translate("dtmUi.quillEditor.onlyImageCanBeUploadedError"));

                return;
            }

            if (file.size > MAX_PICTURE_SIZE_IN_BYTES) {
                this.toastrService.error(
                    this.translocoService.translate("dtmUi.quillEditor.chosenImageIsTooBigError", {
                        maxFileSizeInMegabytes: MAX_PICTURE_SIZE_IN_MEGABYTES,
                    })
                );

                return;
            }

            const reader = new FileReader();
            reader.onload = () => {
                const range = this.quillEditorRef.getSelection();
                const img = `<img src="${reader.result}" />`;
                this.quillEditorRef.clipboard.dangerouslyPasteHTML(range?.index ?? 0, img);
            };
            reader.readAsDataURL(file);
        };

        input.click();
    }
}
