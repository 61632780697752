import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EnrollmentRoutingModule } from "@dtm-frontend/landing-page-lib/enrollment";

const routes: Routes = [{ path: "**", redirectTo: "/" }];

@NgModule({
    imports: [RouterModule.forRoot(routes), EnrollmentRoutingModule],
    exports: [RouterModule],
})
export class AppRoutingModule {}
