import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { PhoneNumber, requiredValidForSmsPhoneNumberValidator } from "@dtm-frontend/shared/ui";
import { DEFAULT_PHONE_COUNTRY_CODE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { tap } from "rxjs/operators";
import { Enrollment, EnrollmentError, EnrollmentErrorType } from "../../../services/models";

interface ConfirmationWizardChangePhoneFormStepComponentState {
    isProcessing: boolean;
    enrollmentError: EnrollmentError | undefined;
    enrollment: Enrollment | undefined;
}

@UntilDestroy()
@Component({
    selector: "dtm-lp-lib-confirmation-wizard-change-phone-form-step",
    templateUrl: "./change-phone-form-step.component.html",
    styleUrls: ["./change-phone-form-step.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ConfirmationWizardChangePhoneFormStepComponent {
    @Output() public phoneNumberUpdate = new EventEmitter<PhoneNumber>();
    @Output() public confirmationCodeResend = new EventEmitter();
    @Output() public back = new EventEmitter();
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }
    @Input() public set enrollmentError(value: EnrollmentError | undefined) {
        this.localStore.patchState({ enrollmentError: value });
    }
    @Input() public set enrollment(value: Enrollment | undefined) {
        this.localStore.patchState({ enrollment: value });
        this.phoneNumberFormControl.setValue({
            countryCode: value?.phoneNumber.countryCode ?? DEFAULT_PHONE_COUNTRY_CODE,
            number: value?.phoneNumber.number ?? "",
        });
    }

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly enrollment$ = this.localStore.selectByKey("enrollment");
    protected readonly phoneNumberFormControl = new FormControl<PhoneNumber>(
        { countryCode: DEFAULT_PHONE_COUNTRY_CODE, number: "" },
        { validators: requiredValidForSmsPhoneNumberValidator, nonNullable: true }
    );
    protected readonly changePhoneFormGroup = new FormGroup<{ phoneNumber: FormControl<PhoneNumber> }>({
        phoneNumber: this.phoneNumberFormControl,
    });
    constructor(private readonly localStore: LocalComponentStore<ConfirmationWizardChangePhoneFormStepComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            enrollmentError: undefined,
            enrollment: undefined,
        });

        this.localStore
            .selectByKey("enrollmentError")
            .pipe(
                tap((error) => {
                    if (!error) {
                        return;
                    }
                    this.phoneNumberFormControl.setErrors({
                        resendConfirmationCode: this.getResendConfirmationCodeErrorValue(error),
                        invalidNumber: error.type === EnrollmentErrorType.InvalidPhoneNumber,
                        alreadyUsed: error.type === EnrollmentErrorType.PhoneNumberAlreadyUsed,
                    });
                    this.phoneNumberFormControl.markAsTouched();
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }

    // TODO: all wizard steps should change submit event to complete(d) event
    protected async trySubmit(event: Event) {
        event.stopPropagation();
        event.preventDefault();
        event.stopImmediatePropagation();

        const isProcessing = this.localStore.selectSnapshotByKey("isProcessing");
        const enrollment = this.localStore.selectSnapshotByKey("enrollment");

        if (this.checkIfFormInvalid()) {
            this.changePhoneFormGroup.markAllAsTouched();

            return;
        }

        if (isProcessing) {
            return;
        }

        const isChangingNumber =
            enrollment?.phoneNumber.number !== this.phoneNumberFormControl.value?.number ||
            enrollment?.phoneNumber.countryCode !== this.phoneNumberFormControl.value?.countryCode;

        if (isChangingNumber) {
            this.phoneNumberUpdate.emit(this.phoneNumberFormControl.getRawValue() as PhoneNumber);
        } else {
            this.confirmationCodeResend.emit();
        }
    }

    private getResendConfirmationCodeErrorValue(error: EnrollmentError): number | false {
        if (error.type === EnrollmentErrorType.ConfirmationCodeRateLimitReached && "date" in error) {
            return this.getRateLimitDateTimeLeft(error.date);
        }

        return false;
    }

    public get rateLimitDateTimeLeft(): number {
        return this.phoneNumberFormControl.getError("resendConfirmationCode");
    }

    private checkIfFormInvalid(): boolean {
        const phoneNumberErrors = this.phoneNumberFormControl.errors;
        if (phoneNumberErrors != null) {
            return !!Object.keys(phoneNumberErrors).find(
                // NOTE: In case resendConfirmationCode is the only error the form should not be blocked.
                (errorName) => errorName !== "resendConfirmationCode" && phoneNumberErrors[errorName]
            );
        }

        return false;
    }
    private getRateLimitDateTimeLeft(date: Date): number {
        return Math.round(Math.max(date.getTime() - Date.now(), 0) / 1000);
    }
}
